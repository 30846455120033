<template>
  <v-container fluid>
    <base-page-title>Create Project</base-page-title>
    <ProjectTemplate :editMode="false" :activeTab="0" />
  </v-container>
</template>
<script>
export default {
  name: "CreateProject",
  components: {
    ProjectTemplate: () => import("./ProjectTemplate.vue"),
  }
};
</script>
